<template>
    <div class="sld_chat_page">

        <chatLeftList @curChatMemInfo="curChatMemInfo" @switchMember="switchMember" ref="left"
            :connectBaseData="connectBaseData" />

        <div class="chat_zone" :style="{ 'background': curChatMemberId ? '#fff' : '#f1f1f2' }">
            <section v-show="curChatMemberId">
                <div class="top">
                    <p class="top_title">{{ curChatMemberInfo.data.memberName }}&nbsp;&nbsp;{{ chatSourceUrl }}</p>
                </div>
                <div class="chat_wrap">
                    <!-- 聊天界面框start -->
                    <div class="chat_window" :style="'height:' + (clientHeight - 339) + 'px'">
                        <el-scrollbar ref="scroll" id="chat">
                            <!-- <div v-if="msgList.list.length>0"> -->
                            <div v-for="(item, index) in msgList.list" :key="index">
                                <div v-if="index == 0 || (index > 0 && $isShowTime(msgList.list[index - 1].createTime, item.createTime))"
                                    class="time_line">
                                    {{ $formatChatTime(item.createTime) }}
                                </div>
                                <!-- 会员发送的信息start -->
                                <template v-if="item.userType == 1">
                                    <!-- 文本类型start -->
                                    <div class="chat_ladder user" v-if="item.msgType == 1">
                                        <img :src="imgUrl + item.memberAvatar" class="conten_left">
                                        <div class="content_right">
                                            <p>{{ item.memberName }}</p>

                                            <span class="content_text text_type"
                                                v-html="JSON.parse(item.msgContent).content"></span>

                                        </div>
                                    </div>
                                    <!-- 文本类型end -->

                                    <!-- 图片类型start -->
                                    <div class="chat_ladder user" v-if="item.msgType == 2">
                                        <img :src="imgUrl + item.memberAvatar" class="conten_left">
                                        <div class="content_right">
                                            <p class="name">{{ item.memberName }}</p>
                                            <div class="content_text image_type">
                                                <el-image :src="imgUrl + JSON.parse(item.msgContent).pic"
                                                    :preview-src-list="[imgUrl + JSON.parse(item.msgContent).pic]"
                                                    hide-on-click-modal="true">
                                                    <template #placeholder>
                                                        <div class="image-slot">
                                                            <i class="el-icon-picture-outline"></i>
                                                        </div>
                                                    </template>
                                                </el-image>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- 图片类型end -->

                                    <!-- 商品类型start -->
                                    <div class="chat_ladder user" v-if="item.msgType == 3">
                                        <img :src="imgUrl + item.memberAvatar" class="conten_left">
                                        <div class="content_right">
                                            <p class="name">{{ item.memberName }}</p>
                                            <div class="content_text goods_type">
                                                <div class="goods_model"
                                                    @click="toDetail(JSON.parse(item.msgContent), 'goods')">
                                                    <img :src="imgUrl + JSON.parse(item.msgContent).goodsImage.split(',')[0]"
                                                        alt="">
                                                    <div class="goods_info">
                                                        <div class="goods_info_title">
                                                            {{ JSON.parse(item.msgContent).goodsName }}
                                                        </div>
                                                        <div class="goods_info_bottom">
                                                            <span>￥{{ JSON.parse(item.msgContent).goodsPrice }}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- 商品类型end -->

                                    <!-- 订单类型start -->
                                    <div class="chat_ladder user" v-if="item.msgType == 4">
                                        <img :src="imgUrl + item.memberAvatar" class="conten_left">
                                        <div class="content_right">
                                            <p class="name">{{ item.memberName }}</p>
                                            <div class="content_text order_type"
                                                @click="toDetail(JSON.parse(item.msgContent), 'order')">
                                                <div class="order_title">
                                                    <span class="order_sn">{{ L['订单号'] }}：<i>{{
                                                        JSON.parse(item.msgContent).orderSn }}</i></span>
                                                    <span class="order_time">{{ JSON.parse(item.msgContent).createTime
                                                        }}</span>
                                                </div>
                                                <div class="goods_model order_type">
                                                    <img :src="imgUrl + JSON.parse(item.msgContent).goodsImage.split(',')[0]"
                                                        alt="">
                                                    <div class="goods_info">
                                                        <div class="goods_info_title">
                                                            {{ JSON.parse(item.msgContent).goodsName }}
                                                        </div>
                                                        <div class="goods_info_bottom goods_info_bottom_detial">
                                                            <span>￥{{ JSON.parse(item.msgContent).moneyAmount ?
                                                                JSON.parse(item.msgContent).moneyAmount :
                                                                JSON.parse(item.msgContent).goodsPrice }}</span>
                                                            <span class="goods_info_bottom_ok">{{
                                                                JSON.parse(item.msgContent).orderStateValue }}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- 订单类型end -->
                                </template>
                                <!-- 会员发送的信息end -->


                                <!-- 客服发送的消息start -->
                                <template v-if="item.userType == 2">
                                    <!-- 文本类型start -->
                                    <div class="chat_ladder merchant" v-if="item.msgType == 1 && item.isRevoke !== 1"
                                        @mouseleave="item.isRevo = false">
                                        <div
                                            :class="{ msg_read: item.msgState == 1, msg_not_read: item.msgState == 2 }">
                                            {{ item.msgState == 1 ? L['已读'] : L['未读'] }}</div>
                                        <div class="content_right">
                                            <p class="name">{{ item.adminName }}</p>
                                            <span class="content_text text_type"
                                                v-html="JSON.parse(item.msgContent).content"
                                                @mouseenter="handleMouseMove(item, index)"></span>
                                        </div>
                                        <div class="line_box" v-show="item.showPop"
                                            @mouseleave="handleMouseleave(item, index)">
                                            <div class="pop_list_l">
                                                <div class="pop_title" @click="revoke(item, index)">
                                                    <div>撤回</div>
                                                </div>
                                                <div class="pop_title" @click="copy(item, index)">
                                                    <div>复制</div>
                                                </div>
                                                <!-- <div class="pop_title" @click="item.showPop = false">
                                                </div> -->
                                            </div>
                                        </div>
                                        <div v-show="item.isRevo" class="content_text_cehui"
                                            @click="item.showPop = true">
                                            <div class="ellipsis">...</div>
                                        </div>
                                        <img :src="imgUrl + item.adminAvatar" alt="" class="user_avatar">

                                    </div>
                                    <div class="time_line" v-show="item.msgType == 1 && item.isRevoke == 1">
                                        你撤回了一条信息
                                    </div>

                                    <!-- 文本类型end -->

                                    <!-- 商品类型start -->
                                    <div class="chat_ladder merchant" v-if="item.msgType == 3 && item.isRevoke !== 1"
                                        @mouseleave="item.isRevo = false">
                                        <div
                                            :class="{ msg_read: item.msgState == 1, msg_not_read: item.msgState == 2 }">
                                            {{ item.msgState == 1 ? L['已读'] : L['未读'] }}</div>
                                        <div class="content_right" @mouseenter="handleMouseMove(item, index)">
                                            <p class="name">{{ item.adminName }}</p>
                                            <div class="content_text goods_type"
                                                @click="toDetail(JSON.parse(item.msgContent), 'goods')">
                                                <div class="goods_model" :key="index">
                                                    <img :src="imgUrl + JSON.parse(item.msgContent).goodsImage.split(',')[0]"
                                                        alt="">
                                                    <div class="goods_info">
                                                        <div class="goods_info_title">
                                                            {{ JSON.parse(item.msgContent).goodsName }}
                                                        </div>
                                                        <div class="goods_info_bottom">
                                                            <span>￥{{ JSON.parse(item.msgContent).goodsPrice }}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="line_box" v-show="item.showPop"
                                            @mouseleave="handleMouseleave(item, index)">
                                            <div class="pop_list_l">
                                                <div class="pop_title" @click="revoke(item, index)">
                                                    <div>撤回</div>
                                                </div>
                                                <div class="pop_title" @click="copy(item, index)">
                                                    <div>复制</div>
                                                </div>
                                                <!-- <div class="pop_title" @click="item.showPop = false">
                                                </div> -->
                                            </div>
                                        </div>
                                        <div v-show="item.isRevo" class="content_text_cehui"
                                            @click="item.showPop = true">
                                            <div class="ellipsisGoods">...</div>
                                        </div>
                                        <img :src="imgUrl + item.adminAvatar" alt="" class="user_avatar">
                                    </div>
                                    <div class="time_line" v-show="item.msgType == 3 && item.isRevoke === 1">
                                        你撤回了一条信息
                                    </div>
                                    <!-- 商品类型end -->

                                    <!-- 订单类型start -->
                                    <div class="chat_ladder merchant" v-if="item.msgType == 4 && item.isRevoke !== 1"
                                        @mouseleave="item.isRevo = false">
                                        <div
                                            :class="{ msg_read: item.msgState == 1, msg_not_read: item.msgState == 2 }">
                                            {{ item.msgState == 1 ? L['已读'] : L['未读'] }}</div>
                                        <div class="content_right" @mouseenter="handleMouseMove(item, index)">
                                            <p class="name">{{ item.adminName }}</p>
                                            <div class="content_text order_type"
                                                @click="toDetail(JSON.parse(item.msgContent), 'order')">
                                                <div class="order_title">
                                                    <span class="order_sn">{{ L['订单号'] }}：<i>{{
                                                        JSON.parse(item.msgContent).orderSn }}</i></span>
                                                    <span class="order_time">{{ JSON.parse(item.msgContent).createTime
                                                        }}</span>
                                                </div>
                                                <div class="goods_model order_type">
                                                    <img :src="imgUrl + JSON.parse(item.msgContent).goodsImage.split(',')[0]"
                                                        alt="">
                                                    <div class="goods_info">
                                                        <div class="goods_info_title">
                                                            {{ JSON.parse(item.msgContent).goodsName }}
                                                        </div>
                                                        <div class="goods_info_bottom goods_info_bottom_detial">
                                                            <span>￥{{ JSON.parse(item.msgContent).goodsPrice }}</span>
                                                            <span class="goods_info_bottom_ok">{{
                                                                JSON.parse(item.msgContent).orderStateValue }}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="line_box" v-show="item.showPop"
                                            @mouseleave="handleMouseleave(item, index)">
                                            <div class="pop_list_l">
                                                <div class="pop_title" @click="revoke(item, index)">
                                                    <div>撤回</div>
                                                </div>
                                                <div class="pop_title" @click="copy(item, index)">
                                                    <div>复制</div>
                                                </div>
                                                <!-- <div class="pop_title" @click="item.showPop = false">
                                                </div> -->
                                            </div>
                                        </div>
                                        <div v-show="item.isRevo" class="content_text_cehui"
                                            @click="item.showPop = true">
                                            <div class="ellipsisOrder">...</div>
                                        </div>
                                        <img :src="imgUrl + item.adminAvatar" alt="" class="user_avatar">
                                    </div>
                                    <div class="time_line" v-show="item.msgType == 4 && item.isRevoke == 1">
                                        你撤回了一条信息
                                    </div>
                                    <!-- 订单类型end -->

                                    <!-- 图片类型start -->
                                    <div class="chat_ladder merchant" v-if="item.msgType == 2 && item.isRevoke !== 1"
                                        @mouseleave="item.isRevo = false">
                                        <div
                                            :class="{ msg_read: item.msgState == 1, msg_not_read: item.msgState == 2 }">
                                            {{ item.msgState == 1 ? L['已读'] : L['未读'] }}</div>
                                        <div class="content_right">
                                            <p class="name">{{ item.adminName }}</p>
                                            <div class="content_text image_type"
                                                @mouseenter="handleMouseMove(item, index)">
                                                <el-image :src="imgUrl + JSON.parse(item.msgContent).pic"
                                                    :preview-src-list="[imgUrl + JSON.parse(item.msgContent).pic]"
                                                    hide-on-click-modal="true">
                                                    <template #placeholder>
                                                        <div class="image-slot">
                                                            <i class="el-icon-picture-outline"></i>
                                                        </div>
                                                    </template>
                                                </el-image>
                                            </div>

                                        </div>
                                        <div class="line_box" v-show="item.showPop"
                                            @mouseleave="handleMouseleave(item, index)">
                                            <div class="pop_list_l">
                                                <div class="pop_title" @click="revoke(item, index)">
                                                    <div>撤回</div>
                                                </div>
                                                <div class="pop_title" @click="copy(item, index)">
                                                    <div>复制</div>
                                                </div>
                                                <!-- <div class="pop_title" @click="item.showPop = false">
                                                </div> -->
                                            </div>
                                        </div>
                                        <div v-show="item.isRevo" class="content_text_cehui"
                                            @click="item.showPop = true">
                                            <div class="ellipsispic">...</div>
                                        </div>
                                        <img :src="imgUrl + item.adminAvatar" alt="" class="user_avatar">
                                    </div>
                                    <div class="time_line" v-show="item.isRevoke == 1 && item.msgType == 2">
                                        你撤回了一条信息
                                    </div>
                                    <!-- 图片类型end -->

                                </template>
                                <!-- 客服发送的消息end -->
                            </div>
                            <!-- </div> -->
                            <!-- 这里加个div标签是防止一开始出现‘can not read property 0 of null’的错误 -->
                            <div></div>
                        </el-scrollbar>
                    </div>
                    <!-- 聊天界面框end -->

                    <!-- 聊天输入框start -->
                    <div class="chat_input" id="chat_input">
                        <div class="chat_tool">
                            <!-- 表情start -->
                            <!-- <div style="display: inline-block;">
                                <span class="face_ex">
                                    <img src="@/assets/service/face_ex.png" alt="" @click="showEmojis= true">
                                </span>
                                <div class="emoji_s" v-if="showEmojis">
                                    <div class="pop_header" @click="showEmojis = false">
                                        <p><i class="el-icon-close" ></i></p>
                                    </div>
                                    <div v-for="(e, i) in emojis" :key="i" :title="e.title" class="emoji_item"
                                        @click="transEmoji(e.src)">
                                        <img :src="require('@/assets/emoji/' + (e.src))" alt="">

                                    </div>
                                </div>
                            </div> -->
                            <div style="display: inline-block;">
                                <span class="face_ex">
                                    <img src="@/assets/service/face_ex.png" alt=""
                                        @click="showEmojis = true, popFlag = false">
                                </span>
                                <div class="emoji_box_x" v-if="showEmojis">
                                    <div class="pop_header">
                                        <p></p>
                                        <p @click="showEmojis = false" style="margin-right: 25px;"><i
                                                class="el-icon-close"></i></p>
                                    </div>
                                    <div class="emoji_list">
                                        <div v-for="(e, i) in emojis" :key="i" :title="e.title" class="emoji_item"
                                            @click="transEmoji(e.src)">
                                            <img :src="require('@/assets/emoji/' + (e.src))" alt="">
                                        </div>

                                    </div>

                                </div>
                            </div>
                            <!-- <el-popover placement="top-start" :width="300" trigger="click">
                                <div class="emoji_s">
                                    <div v-for="(e, i) in emojis" :key="i" :title="e.title" class="emoji_item"
                                        @click="transEmoji(e.src)">
                                        <img :src="require('@/assets/emoji/' + (e.src))" alt="">
                                    </div>
                                </div>
                                <template #reference>
                                    <span class="face_ex">
                                        <img src="@/assets/service/face_ex.png" alt="">
                                    </span>
                                </template>
                            </el-popover> -->
                            <!-- 表情end -->

                            <!-- 图片start -->
                            <label for="image">
                                <img src="@/assets/service/pic.png" alt="">
                            </label>
                            <input type="file" id="image" @change="getImage" ref="imageFile">
                            <!-- 图片end -->

                            <!-- 快捷语恢复start -->
                            <div style="display: inline-block;">
                                <span class="face_ex" @click="showEmojis = false">
                                    <img src="@/assets/service/quick_reply.png" alt="" @click="getQuickReplyActive">
                                </span>
                                <div class="quick_box" v-if="popFlag">
                                    <div class="pop_header">
                                        <p>{{ L['常用语快捷回复'] }}</p>
                                        <p><i class="el-icon-close" @click="popFlag = false"></i></p>
                                    </div>
                                    <div class="pop_list"
                                        v-if="quickReplyList && quickReplyList.list && quickReplyList.list.length > 0">
                                        <div class="pop_item" v-for="(item, index) in quickReplyList.list" :key="index"
                                            @click="sendQuick(item.msgContent)">
                                            {{ item.msgContent }}
                                        </div>
                                    </div>
                                    <div class="empty_quick" v-if="!quickReplyList.list.length > 0">
                                        <img src="@/assets/goods/empty_data.png" alt="">
                                        <p>{{ L['暂未设置快捷回复~'] }}</p>
                                    </div>
                                </div>
                            </div>
                            <!-- <el-popover placement="top-start" :width="780" trigger="click" v-model:visible="popFlag">
                                <template #reference>
                                    <img src="@/assets/service/quick_reply.png" alt="" @click="getQuickReplyActive">
                                </template>
                                
                            </el-popover> -->
                            <!-- 快捷语end -->

                            <!-- 转接start -->
                            <img src="@/assets/service/tran_touch.png" alt="" @click="chatTransfer">
                            <!-- 转接end -->
                        </div>
                        <div class="chat_input_area">
                            <!-- <el-input type="textarea" :rows="4" placeholder="请输入内容" v-model="textarea" resize="none"
                                id="el-textarea" @keyup.enter="send()">
                            </el-input> -->
                            <pre name="sendBox" contenteditable="true" class="send-textarea" @input="preInput"
                                ref="sendBox" @paste="listenPaste" @keyup.enter="send()"></pre>
                        </div>
                        <div class="input_button">
                            <button @click="send()">{{ L['发送'] }}</button>
                        </div>
                    </div>
                    <!-- 聊天输入框end -->
                </div>

                <!-- 客服转接框start -->
                <el-dialog title="客服列表" v-model="dialogVisible" width="20%" :before-close="handleClose" top="25vh">
                    <el-scrollbar>
                        <div class="transfer_list">
                            <div class="transfer_item" v-for="(venItem, venIndex) in vendorList.list" :key="venIndex">
                                <img :src="imgUrl + venItem.adminAvatar" alt="">
                                <div class="text_con">
                                    <p class="service_name">{{ venItem.adminName }}</p>
                                    <p class="service_state">{{ L[venItem.onlineStateValue] }}</p>
                                </div>
                                <img src="@/assets/service/contact_on.png" alt="" @click="vendorTrans(venItem.adminId)">
                            </div>
                            <div class="empty_trans" v-show="!vendorList.list.length > 0">
                                <img src="@/assets/goods/empty_data.png" alt="">
                                <p>{{ L['暂无在线客服~'] }}</p>
                            </div>
                        </div>
                    </el-scrollbar>
                </el-dialog>
                <!-- 客服转接框end -->
            </section>
            <section v-show="!curChatMemberId">
                <div class="empty_data">
                    <img src="@/assets/goods/empty_data.png" />
                    <p>{{ L['请选择访客开启对话～'] }}</p>
                </div>
            </section>
        </div>

        <chatRightList @getObj="getObj" ref="right" />
    </div>
</template>
<script>
import "element-plus/lib/index.full.js"
import { ref, reactive, getCurrentInstance, onMounted, watch, watchEffect } from 'vue'
import chatLeftList from './chatLeftList';
import chatRightList from './chatRightList';
import { useStore } from "vuex";
import { sourceUrl, imageUrl } from '@/utils/config.js'
import { ElMessage, ElImage, ElMessageBox } from 'element-plus'
import { emoji, emojiPath } from '@/utils/data.js';
export default {
    name: 'chatPage',
    components: {
        chatLeftList,
        chatRightList,
        ElImage
    },
    beforeCreate() {
        //监听连接成功事件
        this.sockets.subscribe('get_room_info', (data) => {
            console.log('连接成功', data)
            this.socketInfo.data = data;
        });
        this.sockets.subscribe('get_send_msg', (data) => {
            //如果是会员发送过来的话需要播放声音
            if (data.userType == 1 && (localStorage.msgVoiceSetting === true || localStorage.msgVoiceSetting === 'true')) {
                this.play();
            }
            this.msgList.list.push(data);
        });
        this.sockets.subscribe('get_member_source_url', (data) => {
            this.chatSourceUrl = decodeURIComponent(data.sourceUrl);
        });
        this.sockets.subscribe('get_read_msg', (data) => {
            document.title = '平台-客服助手'
            let allData = data.msgIds.split(',');
            // console.log(11111)

            this.msgList.list.map(item => {
                if (allData.indexOf(item.msgId)) {
                    item.msgState = 1;
                }
            });
        });
        this.sockets.subscribe('get_member_read_all', () => {
            document.title = '平台-客服助手'

            this.msgList.list.map(item => {
                item.msgState = 1;
            });
        });

    },
    setup(props, { emit }) {
        const connectBaseData = { userId: localStorage.adminId, role: 2 };
        // const connectBaseData = { userId: 1, role: 2 };
        const { proxy } = getCurrentInstance();
        const store = useStore();
        const quickReplyList = reactive({
            list: []
        })
        const L = proxy.$getCurLanguage()

        const storeInfo = reactive({ data: store.state.storeInfo });
        const msgVoiceSetting = ref(store.state.msgVoiceSetting);
        const textarea = ref('');
        const chatSourceUrl = ref('');//当前会员的页面来源
        const curChatMemberId = ref('');//当前聊天窗口会员Id
        const curChatMemberInfo = reactive({ data: {} });//当前聊天会员的信息
        const socketInfo = reactive({ data: {} });//socket连接成功返回的房间信息
        const msgList = reactive({ list: [] });//聊天列表
        const dialogVisible = ref(false);
        const popFlag = ref(false)
        const hasMore = ref(true);
        const revokeindex = ref(false);
        const showPop = ref(false);


        const revokeNews = ref('');


        const minMsgId = ref('');//当前消息聊天记录列表里的最小消息id
        const vendorList = reactive({
            list: []
        })
        const scrollTop = ref(false)
        const flag = ref('')
        const clientHeight = ref(0)
        const emojis = reactive(emoji)
        const showEmojis = ref(false)
        // const imgUrl = ref('https://image.qingfeifeicui.com');
        const imgUrl = ref(imageUrl)
        const isRevokeShow = ref(false)



        // console.log('emojis---', emojis)
        //发送消息
        const send = () => {
            let contentTest = textarea.value.replace(/&nbsp;|\s+/g, ''); //没有内容的话直接结束
            if (!contentTest.trim() && !contentTest) {
                return false;
            }

            if (contentTest.length > 50000) {
                ElMessage('超过最大字符限制')
                return
            }
            let content = textarea.value; //没有内容的话直接结束

            let msgData = {};
            msgData.memberId = socketInfo.data.memberId;
            msgData.adminId = socketInfo.data.adminId;
            msgData.msgType = '1';
            msgData.msg = {
                content: content
            };
            proxy.$socket.emit("send_msg", { ...msgData, ...connectBaseData });
            textarea.value = '';//清空输入框的内容
            proxy.$refs.sendBox.innerHTML = ''
            scrollTop.value = false
            showEmojis.value = false
            scrolltoNewMsg()
        }

        //打开客服转接
        const chatTransfer = () => {
            getListOnline()
            dialogVisible.value = true
            proxy.$socket.emit('pre_switch_admin', { ...connectBaseData }, (data) => {
                console.log(77, data)
                // vendorList.list = data
            })
        }


        // 点击转接客服
        const vendorTrans = (adminId) => {
            console.log('点击了跳转连接',adminId)
            proxy.$socket.emit('switch_admin', { switchAdminId: adminId, memberId: curChatMemberId.value, ...connectBaseData }, (res) => {
                console.log('进入了连接地址',res)
                if (res.state == 200) {
                    //删除将该会员从最近联系人列表中删除
                    proxy.$refs.left.closeChatMember(curChatMemberId.value)
                    ElMessage({
                        showClose: true,
                        message: res.msg,
                        type: 'success'
                    });
                } else {
                    ElMessage({
                        showClose: true,
                        message: res.msg,
                        type: 'error'
                    });
                }
            })
                dialogVisible.value = false;
                // proxy.$refs.left.closeChatMember(curChatMemberId.value)


        }

        //  //获取在线客服列表
        const getListOnline = () => {
            let params = {
                isExclude: true
            }
            proxy.$get('v3/system/admin/adminUser/listOnline', params).then(res => {
                if (res.state == 200) {
                    vendorList.list = res.data
                }

            })
        }

        //获取现在的会员的信息
        const curChatMemInfo = (data) => {
            if (data.memberId != undefined && data.memberId) {
                curChatMemberInfo.data = data;
            } else {
                curChatMemberId.value = 0;
            }
        }

        //切换会员需要重新获取聊天列表以及用户的信息
        const switchMember = (data) => {
            let { memberId, memberName } = data;
            curChatMemberInfo.data.memberName = memberName;
            curChatMemberId.value = memberId;
            emit('getCurMemberId', data)
            scrollTop.value = false
            hasMore.value = true
            flag.value = memberId.toString()
            minMsgId.value = '';
            msgList.list = [];
            getChatLog();
            proxy.$refs.right.getSwitch(curChatMemberId.value)

        }

        //获取订单，足迹，推荐商品，并发送
        const getObj = (data, type) => {
            let msgData = {};
            msgData.memberId = socketInfo.data.memberId;
            msgData.adminId = socketInfo.data.adminId;
            if (type == 'foot') {
                msgData.msgType = 3;
                msgData.msg = {
                    productId: data.productId,
                    goodsImage: data.goodsImage,
                    goodsName: data.goodsName,
                    goodsPrice: data.productPrice,
                    goodsId: data.goodsId
                };
            } else if (type == 'recom') {
                msgData.msgType = 3;
                msgData.msg = {
                    productId: data.productId,
                    goodsImage: data.masterImg,
                    goodsName: data.goodsName,
                    goodsPrice: data.goodsPrice,
                    goodsId: data.goodsId
                };
            } else if (type === 'order') {
                msgData.msgType = 4;
                msgData.msg = {
                    orderSn: data.orderSn,
                    createTime: data.createTime,
                    orderStateValue: data.orderStateValue,
                    productId: data.goodsItem.productId,
                    goodsImage: data.goodsItem.productImage,
                    goodsName: data.goodsItem.goodsName,
                    goodsPrice: data.goodsItem.moneyAmount,
                };
            }
            proxy.$socket.emit("send_msg", { ...msgData, ...connectBaseData });
            scrollTop.value = false
            scrolltoNewMsg()
        }

        //获取快捷回复列表
        const getQuickReplyActive = () => {
            popFlag.value = true
            console.log('popFlag--', popFlag.value)
            let params = {
                current: 1
            }
            proxy.$get('v3/helpdesk/admin/quick/list', params).then(res => {
                if (res.state == 200) {
                    quickReplyList.list = res.data.list.filter(item => item.isShow == 1)
                }

            })
        }

        //获取聊天记录
        const getChatLog = () => {
            let params = {
                memberId: curChatMemberId.value,
            }
            if (minMsgId.value) {
                params.msgId = minMsgId.value;
            }
            proxy.$post('v3/helpdesk/admin/chat/msgLog', params).then(res => {
                if (res.state == 200) {
                    res.data.map(e => {
                        e.isRevo = false
                        e.showPop = false
                    })
                    // console.log(11111, res.data)
                    if (minMsgId.value) {
                        msgList.list = Array.from(res.data).concat(msgList.list);
                    } else {
                        msgList.list = res.data
                    }
                    if (res.data.length > 0) {
                        changeMsgState(res.data);
                    }
                    if (msgList.list.length > 0) {
                        minMsgId.value = msgList.list[0].msgId;
                    }
                    hasMore.value = res.data.length < 10 ? false : true;
                }
            })
        }

        const transEmoji = (src) => {
            const imgSrc = (emojiPath) + "" + (src);
            const imgTag = document.createElement("img");
            imgTag.src = imgSrc;
            proxy.$refs.sendBox.appendChild(imgTag);
            textarea.value = proxy.$refs.sendBox.innerHTML
        }


        const preInput = (e) => {
            var re = /<[^img][^>]+>/g
            textarea.value = e.target.innerHTML.replace(re, "")
        }

        //修改当前消息列表的未读消息为已读
        const changeMsgState = (data) => {
            let tmpMsgIdArray = [];
            data.map(item => {
                if (item.userType == 1 && item.msgState == 2) {
                    tmpMsgIdArray.push(item.msgId);
                }
            });
            if (tmpMsgIdArray.length > 0) {
                proxy.$socket.emit("read_msg", {
                    msgIds: tmpMsgIdArray.join(','),
                    memberId: curChatMemberId.value,
                    ...connectBaseData
                });
            }
        }




        //切换店铺的滑动操作，而且触顶加载则保持滚动条在触顶时的位置，不大跳
        watch(() => flag.value, (nv, ov) => {
            let el = ''
            let client = ''
            if (nv != ov) {
                el = document.getElementById('chat').childNodes[0]
                client = document.getElementById('chat')
                scrollTop.value = false
                new MutationObserver(() => {
                    if (scrollTop.value) {
                        el.scrollTop = client.clientHeight + 400
                        return
                    }
                    scrollToBottom(el);
                }).observe(el, { childList: true, subtree: true });

            }
        })

        watchEffect(() => {
        })


        //滑动至底部方法
        const scrollToBottom = el => {
            el.scrollTop = el.scrollHeight;
            el.scrollTo(0, el.scrollHeight)
        };

        //MutationObserver监听dom变化，当聊天数据最终渲染完成时，将其滑动至底部(优化方法)
        const scroll = () => {
            let el = proxy.$refs.scroll.wrap
            let timeout = ''
            el.addEventListener('scroll', () => {
                if (timeout) clearTimeout(timeout);
                timeout = window.setTimeout(function () {
                    if (el.scrollTop == 0 && hasMore.value) {
                        scrollTop.value = true
                        getChatLog()
                    }
                }, 500);
            }, true);
            (new MutationObserver(() => {
                if (scrollTop.value) {
                    el.scrollTop = el.clientHeight + 400
                    return
                }
                scrollToBottom(el);
            })).observe(el, { childList: true, subtree: true });
        }

        //发送快捷回复
        const sendQuick = (msg) => {
            let msgData = {};
            msgData.memberId = socketInfo.data.memberId;
            msgData.adminId = socketInfo.data.adminId;
            msgData.msgType = '1';
            msgData.msg = {
                content: msg
            };

            popFlag.value = false
            proxy.$socket.emit("send_msg", { ...msgData, ...connectBaseData });
            scrollTop.value = false
            scrolltoNewMsg()
        }

        //发送消息时并，滚到最底部
        const scrolltoNewMsg = () => {
            var div = proxy.$refs.scroll.wrap
            proxy.$nextTick(() => {
                div.scrollTo(0, div.scrollHeight + 10000)
            })

        }

        // 消息提示音
        const play = () => {
            let audioElement = document.createElement('audio');
            let voice = require('@/assets/voice/msg.mp3')
            audioElement.setAttribute('src', voice);
            audioElement.setAttribute('autoplay', 'autoplay');
        }


        // 发送图片
        const getImage = (e) => {
            console.log(1, e)
            let tar_file = e.target.files[0]

            if (tar_file.type.indexOf('image') < 0) {
                ElMessage.warning('请选择图片类型文件')
                return
            }

            if (e.target.files[0]) {
                proxy.$post('v3/oss/admin/upload', {
                    source: 'adminHelpDesk',
                    file: e.target.files[0]
                }, 'form').then(res => {
                    if (res.state == 200) {
                        proxy.$refs.imageFile.value = ""
                        let msgData = {}
                        msgData.memberId = socketInfo.data.memberId;
                        msgData.adminId = socketInfo.data.adminId;
                        msgData.msgType = '2';
                        msgData.msg = {
                            pic: res.data.url,
                            width: res.data.width,
                            height: res.data.height
                        };
                        proxy.$socket.emit("send_msg", { ...msgData, ...connectBaseData });
                        proxy.$nextTick(() => {
                            setTimeout(() => {
                                scrollTop.value = false
                                scrolltoNewMsg()
                            }, 10)

                        })

                    }

                })
            }
        }

        const toDetail = (item, type) => {
            switch (type) {
                case 'order': {
                    let url
                    url = `${sourceUrl}manage_order/order_lists_to_detail?orderSn=${item.orderSn}`

                    window.open(url, '_blank')
                    break;
                }

                case 'goods': {
                    let url
                    // if (pcUrl.trim()) {
                    //     url = `${pcUrl}goods/detail?productId=${item.defaultProductId || item.productId}`
                    // } else {
                    //     url = `${sourceUrl}manage_goods/list_to_detail?id=${item.goodsId}`
                    // }
                    url = `${sourceUrl}manage_goods/list_to_detail?id=${item.goodsId}`
                    window.open(url, '_blank')
                    break;
                }
            }
        }
        // 撤回
        const revoke = (item, index) => {
            console.log("item", item);
            console.log("index", index);
            revokeNews.value = index

            ElMessageBox.confirm(
                '确定要撤回吗？',
                '',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            )
                .then(() => {
                    proxy.$get('v3/helpdesk/admin/chat/revokeMsg', {
                        msgId: item.msgId
                    }).then(res => {
                        if (res.state == 200) {
                            // memberId.value = ''
                            // msgId.value = ''
                            revokeindex.value = true
                            showPop.value = false

                            minMsgId.value = '';
                            msgList.list = [];
                            getChatLog();

                        }
                    })
                })
                .catch(() => {
                    ElMessage({
                        type: 'info',
                        message: '取消撤回',
                    })
                })


        }
        const handleMouseMove = (item, index) => {
            console.log("item", item);
            console.log("index", index);
            if (String(index)) {
                isRevokeShow.value = true
                item.isRevo = true

            }

        }


        const handleMouseleave = (item, index) => {
            setTimeout(() => {
                item.showPop = false

            }, 500)
        }
        // 复制
        const copy = (item, index) => {
            const input = document.createElement('input') // 创建一个input
            document.body.appendChild(input) // 将新节点添加到末尾
            const contValue = JSON.parse(item.msgContent)// 需要复制的元素赋值给input
            input.value = contValue.content
            if (!contValue.content) {
                input.select() // 选中input元素
                document.body.removeChild(input) // 删除input
                ElMessage.error('无法复制')
                return

            }
            input.select() // 选中input元素
            document.execCommand('Copy') // 复制
            document.body.removeChild(input) // 删除input
            ElMessage.success('复制成功')
        }

        const listenPaste = (e) => {
            if (e.clipboardData && e.clipboardData.items) {
                let items = e.clipboardData.items || [];
                let file = null;
                if (items && items.length) {
                    for (let i = 0; i < items.length; i++) {
                        if (items[i].type.indexOf('image') !== -1) {
                            file = items[i].getAsFile();
                        }
                    }
                }
                // 获取文件之后就可以上传服务器或者其他操作啦
                if (file) {
                    let obj = {
                        target: {
                            files: [file]
                        }
                    }
                    proxy.$confirm('是否要发送剪切板的图片?', '提示', {
                        confirmButtonText: '确认',
                        cancelButtonText: '取消'
                    }).then(() => {
                        getImage(obj)
                    })
                } else {
                    textFormat(e)
                }
            }
        }

        //复制其他地方的文本需要这样的特殊处理,不能简单的innerHTML = xxxxx
        const textFormat = (e) => {
            e.preventDefault()
            var text
            var clp = (e.originalEvent || e).clipboardData
            if (clp === undefined || clp === null) {
                text = window.clipboardData.getData('text') || ''
                if (text !== '') {
                    if (window.getSelection) {
                        var newNode = document.createElement('span')
                        newNode.innerHTML = text
                        window.getSelection().getRangeAt(0).insertNode(newNode)
                    } else {
                        document.selection.createRange().pasteHTML(text)
                    }
                }
            } else {
                text = clp.getData('text/plain') || ''
                if (text !== '') {
                    document.execCommand('insertText', false, text)
                }
            }
        }




        onMounted(() => {
            clientHeight.value = document.body.clientHeight || document.documentElement.clientHeight
            scroll()
        })

        return {
            textarea,
            imgUrl,
            msgList,
            socketInfo,
            send,
            chatTransfer,
            dialogVisible,
            getObj,
            getQuickReplyActive,
            quickReplyList,
            sendQuick,
            popFlag,
            getChatLog,
            chatSourceUrl,
            curChatMemInfo,
            curChatMemberInfo,
            switchMember,
            minMsgId,
            changeMsgState,
            curChatMemberId,
            vendorList,
            vendorTrans,
            play,
            msgVoiceSetting,
            getImage,
            connectBaseData,
            storeInfo,
            clientHeight,
            emojis,
            preInput,
            transEmoji,
            emojiPath,
            showEmojis,
            L,
            toDetail,
            listenPaste,
            handleMouseMove,
            handleMouseleave,
            isRevokeShow,
            revoke,
            revokeNews,
            revokeindex,
            showPop,
            getListOnline,
            copy

        }
    }
}
</script>
<style lang="scss" scoped>
.pop_header {
    height: 38px;
    background: #F3F3F4;
    display: flex;
    justify-content: space-between;

    p {
        margin-left: 10px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        line-height: 38px;
    }

    i {
        font-size: 16px;
        margin-right: 10px;
    }
}

.emoji_s {
    height: 200px;
    overflow: auto;
    display: flex;
    flex-wrap: wrap;
    position: absolute;
    top: -200px;
    width: 100%;
    background-color: #FFFFFF;

    .emoji_item {
        padding: 0 5px;
    }
}

.emoji_list {
    display: flex;
    flex-wrap: wrap;
    height: 140px;
}

.send-textarea {
    box-sizing: border-box;
    width: 100%;
    height: 94px;
    padding: 0px 19px 0px 19px;
    background-color: #fff;
    color: #252525;
    font-size: 14px;
    font-family: inherit;
    word-break: break-all;
    white-space: normal;
    overflow-y: auto;
    outline: none;

    img {
        width: 16px;
        height: 16px;
    }
}

.emoji_item {
    padding: 0 5px;
}

.quick_box {
    position: absolute;
    top: -400px;
    background-color: #fff;
    padding-bottom: 10px;
}

.emoji_box {
    position: absolute;
    top: -400px;
    background-color: #fff;
    padding-bottom: 10px;
}

.emoji_box_x {
    width: 100%;
    position: absolute;
    top: -180px;
    left: 0px;
    background-color: #fff;
    padding-bottom: 10px;
}

.pop_list {
    margin-top: 5px;
    height: 350px;
    overflow-y: scroll;

    .pop_item {
        margin-left: 10px;
        padding: 12px 0px;
        border-bottom: 1px solid #e7e7e7;
        width: 97%;

        &:hover {
            background: #F8F8F8;
        }
    }
}

.goods_info_bottom_detial {
    position: relative;

    .goods_info_bottom_ok {
        position: absolute;
        padding-left: 5px;
        padding-right: 5px;
        height: 20px;
        background: #EEEEEE;
        border-radius: 10px;
        bottom: 0;
        right: 10px;
        text-align: center;
        line-height: 20px;
        color: #666666 !important;
    }
}

#el-textarea {
    border: none;

    textarea {
        outline: none;
        border: none;
    }
}


.sld_chat_page {
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    background-color: #fff;
    display: flex;
    flex-shrink: 0;

    .chat_zone {
        width: 1010px;
        height: 100%;

        .empty_data {
            padding-top: 220px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            img {
                width: 80px;
            }

            p {
                margin-top: 10px;
                font-size: 12px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #bbb;
            }
        }

        .top {
            height: 50px;
            padding: 15px 0px;

            .top_title {
                height: 20px;
                line-height: 20px;
                border-left: 4px solid #0563FF;
                padding-left: 10px;
                margin-left: 20px;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #333333;
            }
        }

        .chat_wrap {
            width: 100%;
            border-radius: 6px;
            /* border-top-right-radius: 6px; */
            border: 1px solid #EEEEEE;

            .chat_window {
                height: 580px;
                background-color: #F5F5F5;
                padding-top: 12px;
                padding-left: 15px;
                padding-right: 13px;

                .time_line {
                    margin: 12px auto;
                    line-height: 22px;
                    text-align: center;
                    font-size: 12px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #999999;
                    width: 149px;
                    height: 22px;
                    background: #EEEEEE;
                    border-radius: 11px;
                }

                #text_center {
                    width: 589px;
                    height: 104px;
                    background: #FFFFFF;
                    border-radius: 6px;

                    .goods_model {
                        display: flex;
                        justify-content: center;
                        align-content: center;
                        padding-top: 10px;
                        padding-left: 10px;
                    }
                }

                .chat_ladder {
                    display: flex;
                    align-items: flex-end;
                    margin-top: 20px;
                    padding-bottom: 45px;
                    align-items: flex-start;
                    box-sizing: border-box;

                    &>img {
                        width: 38px;
                        height: 38px;
                        border-radius: 50%;

                        &.user_avatar {
                            margin-left: 10px;
                        }
                    }

                    .content_right {
                        margin-left: 10px;

                        p {

                            font-size: 12px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #999999;
                            margin-bottom: 5px;
                        }

                        .content_text {
                            display: inline-block;
                            padding: 11px;
                            position: relative;
                            max-width: 589px;
                            background: #FFFFFF;
                            border-radius: 6px;

                        }


                        .order_type {
                            cursor: pointer;

                            .goods_info {
                                min-width: 300px;

                                .goods_info_title {

                                    font-size: 16px;
                                    font-family: Microsoft YaHei;
                                    font-weight: 400;
                                    color: #333333;
                                }
                            }

                            .order_title {
                                display: flex;
                                height: 20px;
                                justify-content: space-between;
                                align-items: flex-start;
                                border-bottom: 1px solid #F2F2F2;
                                margin-bottom: 8px;

                                .order_sn {
                                    font-size: 12px;
                                    color: #999999;
                                    font-family: Microsoft YaHei;

                                    i {
                                        font-style: normal;
                                        color: #666666;
                                    }
                                }

                                .order_time {
                                    color: #999999;
                                }
                            }
                        }

                        .goods_type {
                            min-width: 300px;
                            cursor: pointer;
                        }

                        .image_type {

                            min-height: 100px;

                            img {
                                max-width: 200px;
                            }

                            .image-slot {
                                width: 200px;
                                height: 200px;
                            }
                        }

                        .text_type {
                            max-width: 360px;
                            word-break: break-all;
                            align-items: center;
                            display: flex;

                            white-space: pre-wrap !important;
                            text-overflow: unset !important;
                            flex-wrap: wrap;
                            word-break: break-word;
                            word-wrap: break-word;

                            img {
                                width: 16px;
                                height: 16px;
                            }
                        }
                    }

                }


                .user {
                    justify-content: flex-start;

                    .content_text {
                        background: #FFFFFF;

                        border-radius: 6px;
                    }
                }

                .merchant {
                    padding-right: 20px;
                    justify-content: flex-end;

                    p {
                        text-align: right;
                    }

                    .content_text {
                        .tiny_triangle {
                            position: absolute;
                            right: -9px;
                            width: 0;
                            height: 0;
                            border-right: 14px solid transparent;
                            border-bottom: 13px solid #fff;
                        }

                        background: #fff;
                        border-radius: 6px;
                    }

                    .msg_read {
                        align-self: center;
                        font-size: 12px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #0E6FD7;
                    }

                    .msg_not_read {
                        align-self: center;
                        height: 100%;
                        font-size: 12px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #0E6FD7;
                    }
                }
            }

            .chat_input {
                padding-bottom: 10px;

                .chat_tool {
                    padding: 6px;
                    position: relative;

                    img {
                        margin-right: 10px;
                    }
                }

                .chat_input_area {
                    padding: 6px;
                    margin-top: 6px;

                    textarea {
                        max-height: 94px;

                    }
                }

                button {
                    width: 80px;
                    height: 30px;
                    background: #0E6FD7;
                    border-radius: 6px;
                    border: none;
                    color: #fff;
                }

                .input_button {
                    display: flex;
                    justify-content: flex-end;
                    padding-right: 23px;

                    button {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }


            }
        }
    }
}

.goods_model {
    display: flex;

    &>img {
        width: 84px;
        height: 84px;
        background: #707070;
        border-radius: 6px;
    }

    .goods_info {
        margin-left: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        max-width: 450px;

        .goods_info_title {
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            line-height: 18px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            padding-right: 10px;
        }

        .goods_info_bottom {
            display: flex;
            justify-content: flex-start;

            span:first-child {
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #e2231a;
            }

            span:nth-child(2) {
                font-size: 12px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #e2231a;
            }
        }
    }
}

.transfer_list {
    padding: 11px 0px 11px 11px;
    height: 317px;

    .transfer_item {
        display: flex;
        border-bottom: 1px solid #F2F2F2;
        padding: 12px 0px;
        position: relative;
        height: 70px;
        width: 98%;

        img {
            width: 40px;
            height: 40px;
        }

        img:nth-of-type(2) {
            position: absolute;
            right: 10px;
            top: 22px;
            width: 23px;
            height: 22px;
        }

        .text_con {
            margin-left: 10px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .service_name {

                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #333333;

            }

            .service_state {

                font-size: 12px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #0E6FD7;
                margin-bottom: 3px;
            }
        }

    }
}

.el-dialog__header {

    background: #F3F3F4;
}

.el-dialog__title {

    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
}

.empty_quick {
    height: 330px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
        width: 87px;
        height: 55px;
    }

    p {
        margin-top: 11px;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
    }
}

.empty_trans {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 90%;

    p {
        margin-top: 11px;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
    }
}

#image {
    display: none;
}



.content_text_cehui {
    position: relative;

    // float: right;
    .ellipsis {
        position: absolute;
        top: 52px;
        left: 3px;
        padding: 4px 7px;
        background: #FFFFFF;
        border-radius: 2px;

        &:hover {
            background: #F8F8F8;
        }
    }

    .ellipsisGoods {
        position: absolute;
        top: 104px;
        left: 3px;
        padding: 4px 7px;
        background: #FFFFFF;
        border-radius: 2px;

        &:hover {
            background: #F8F8F8;

        }

    }

    .ellipsisOrder {
        position: absolute;
        top: 130px;
        left: 3px;
        padding: 4px 7px;
        background: #FFFFFF;
        border-radius: 2px;

        &:hover {
            background: #F8F8F8;

        }
    }

    .ellipsispic {
        position: absolute;
        top: 130px;
        left: 3px;
        padding: 4px 7px;
        background: #FFFFFF;
        border-radius: 2px;

        &:hover {
            background: #F8F8F8;

        }
    }

}

.line_box {
    position: relative;


}

.pop_list_l {
    height: 70px;
    width: 100px;
    margin-top: 5px;
    background-color: #fff;

    // line-height: 24px;
    position: absolute;
    right: -15px;
    bottom: -58px;
    box-shadow: 0px 0px 0px 2px rgb(225 211 211 / 50%);

    // margin-top: 5px;

    .pop_title {
        display: flex;
        // margin-left: 10px;
        border-bottom: 1px solid #e7e7e7;

        padding: 10px 26px;
        width: 97%;
        color: black;

        &:hover {
            background: #F8F8F8;
        }
    }
}
</style>
