<template>
    <div class="sld_chat_left">
        <div class="top">
            <div class="info">
                <img :src="imgUrl + adminInfo.admin_logo" alt="">
                <div class="text">
                    <p>
                        <span>{{ decodeURIComponent(adminName) }}</span>
                        <img src="@/assets/service/service_logo.png" alt="">
                    </p>
                    <!-- <p>({{L['在线中']}})</p> -->

                    <p>{{ onlineStateValue + '中' }}</p>


                </div>
            </div>
        </div>
        <div class="list_wrap">
            <div class="list_con" :style="'height:' + (clientHeight - 152) + 'px'">
                <el-scrollbar id="leftScroll">
                    <div v-if="tabIndex == 1 && chatList.list.length" v-infinite-scroll="load"
                        :infinite-scroll-distance="10">
                        <div class="list_item"
                            :style="{ 'background-color': item.memberId == curMemberId ? '#F5F5F5' : '#fff' }"
                            v-for="(item, index) in chatList.list" :key="index" id="processing"
                            @click="switchMember(item.memberId, item.memberName, item)">
                            <img :src="imgUrl + item.memberAvatar">
                            <div class="list_text">
                                <p class="list_item_title">{{ item.memberName }}</p>
                                <p class="msg_new">{{ item.showContent }}</p>
                            </div>
                            <div class="delete_icon">
                                <i v-if="item.receiveMsgNumber" class="msg_icon">{{ item.receiveMsgNumber > 99 ? '99+' :
                                    item.receiveMsgNumber }}</i>
                                <div class="del_icon">
                                    <img src="@/assets/service/delete.png" alt=""
                                        @click="closeChatMember(item.memberId)">
                                    <!-- <el-popconfirm title="确定结束与该会员的聊天吗？" @confirm="closeChatMember(item.memberId)"
                                        :confirmButtonText="L['确定']" :cancelButtonText="L['取消']">
                                        <template #reference>
                                            <img src="@/assets/service/delete.png" alt="">
                                        </template>
</el-popconfirm> -->
                                </div>
                            </div>
                        </div>
                        <loadingState v-if="loadState == 'first_loading' || chatList.list.length > 0"
                            :state='loadState' />
                    </div>
                    <div class="empty_data_left" v-if="!chatList.list.length > 0">
                        <img src="@/assets/goods/empty_data.png" alt="">
                        <p>{{ L['暂无数据～'] }}</p>
                    </div>
                </el-scrollbar>
                <!-- <div v-if="tabIndex==2">
                    <div class="list_item" v-for="(item,index) in [1,2,3,4,5]" :key="index" id="queuing">
                        <img src="@/assets/service/avatar.png" alt="">
                        <div class="list_text">
                            <p class="list_item_title">aawdawd{{item}}</p>
                            <p class="msg_new">awdawddadawdawdawdawdawdawdawdawdawd</p>
                        </div>
                        <div class="delete_icon">
                            <img src="@/assets/service/delete.png" alt="">
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
import { ref, getCurrentInstance, reactive, onMounted, inject, watchEffect, watch } from 'vue';
import { useStore } from "vuex";
import loadingState from '@/components/loadingState'
import { ElMessage, ElMessageBox } from 'element-plus'
import { imageUrl } from '@/utils/config.js'
export default {
    name: 'chatLeftList',
    components: {
        loadingState
    },
    props: ['connectBaseData'],
    beforeCreate() {
        this.sockets.subscribe('contact_change', (e) => {
            if (this.chatList.list.length == 0) {
                this.chatList.list.push(e);
                this.$socket.emit("admin_change_room", { memberId: e.memberId, ...this.connectData }, () => {
                    this.emitParentData({ memberId: e.memberId, memberName: e.memberName });
                });
            } else {
                let cur_index = this.chatList.list.findIndex(item => item.memberId == e.memberId);
                if (cur_index == -1) {
                    this.chatList.list.unshift(e);
                } else if (cur_index != 0) {
                    this.chatList.list.splice(cur_index, 1)
                    this.chatList.list.unshift(e);
                } else {
                    this.chatList.list[cur_index] = e;
                }


                if (this.curMemberId != e.memberId) {
                    if ((localStorage.msgVoiceSetting === true || localStorage.msgVoiceSetting === 'true')) {
                        this.play();
                    }
                }
            }
            this.formatMsgContent();
        });
        this.sockets.subscribe('unread_num_change', (e) => {
            let tmp_data = this.chatList.list.filter(item => item.memberId == e.memberId)
            if (tmp_data.length == 1) {
                document.title = '平台-客服助手'
                tmp_data[0].receiveMsgNumber = e.unreadNum;
            }
        });
    },
    setup(props, { emit }) {
        const { proxy } = getCurrentInstance();
        const connectData = reactive(props.connectBaseData);
        const store = useStore();
        const tabIndex = ref(1);
        const storeInfo = reactive({ data: store.state.storeInfo });
        const pageSize = 20;
        const current = ref(1);
        const curMemberId = ref(0);//当前聊天会员的id
        const minMsgId = ref('');//当前消息的最小id
        const chatList = reactive({ list: [] });//最近联系人列表
        const loadState = ref('')
        const changeTab = (index) => {
            tabIndex.value = index
        }
        const adminInfo = inject('adminInfo')
        // const imgUrl = ref('https://image.qingfeifeicui.com');
        const imgUrl = ref(imageUrl)
        const adminLogo = require('@/assets/adminLogo.png')
        const isScroll = ref(false)
        const hasMore = ref(true)
        const clientHeight = ref(0)
        const L = proxy.$getCurLanguage()
        const adminName = localStorage.adminName
        const onlineStateValue = ref('在线')




        //获取最近联系人
        const getChatList = () => {
            const params = {};
            params.pageSize = pageSize;
            params.current = current.value;

            if (minMsgId.value) {
                params.msgId = minMsgId.value;
            }
            proxy.$get('v3/helpdesk/admin/chat/list', params).then(res => {
                if (res.state == 200) {
                    if (minMsgId.value) {
                        chatList.list = chatList.list.concat(res.data);
                    } else {
                        chatList.list = res.data
                    }
                    formatMsgContent()
                    if (chatList.list.length > 0) {
                        minMsgId.value = chatList.list[chatList.list.length - 1].msgId
                        if (proxy.$socket.connected) {
                            proxy.$socket.emit("admin_change_room", { memberId: curMemberId.value, ...connectData }, () => {
                                if (isScroll.value == false) {
                                    emit('switchMember', { memberId: curMemberId.value, memberName: chatList.list[0].memberName });
                                }
                            });
                        }
                    } else {
                        curMemberId.value = 0;
                        emit('curChatMemInfo', {})
                    }
                    hasMore.value = res.data.length < 10 ? false : true;
                    if (hasMore.value) {
                        loadState.value = 'allow_loading_more';
                    } else {
                        loadState.value = 'no_more_data';
                    }
                }
            })
        }
        //获取在线离线状态
        const getlineState = () => {
            proxy.$get('v3/system/admin/adminUser/detail').then(res => {
                onlineStateValue.value = res.data.onlineStateValue
            })


        }
        //格式化聊天内容，方便列表展示
        const formatMsgContent = () => {
            let reg = /<img [^>]*src=['"]([^'"]+)[^>]*>/g

            let reg4 = /(<\/?div.*?>)|(<\/?br.*?>)|(<\/?span.*?>)/g;
            if (chatList.list.length > 0) {
                chatList.list.map(item => {
                    if (typeof item.msgContent == 'string') {
                        item.msgContent = JSON.parse(item.msgContent)
                    }
                    //1.text(文本) 2.img(图片) 3.goods(商品) 4.order(订单)用户
                    if (item.msgType == 1) {

                        if (reg.test(item.msgContent.content)) {
                            item.msgContent.content = item.msgContent.content.replace(reg, L['[表情]'])
                            item.showContent = item.msgContent.content
                        } else {
                            item.showContent = item.msgContent.content;
                        }

                        if (reg4.test(item.msgContent.content)) {
                            item.msgContent.content = item.msgContent.content.replace(reg4, '')
                            item.showContent = item.msgContent.content
                        } else {
                            item.showContent = item.msgContent.content;
                        }

                    } else if (item.msgType == 2) {
                        item.showContent = L['[图片]'];
                    } else if (item.msgType == 3) {
                        item.showContent = L['[商品]'];
                    } else if (item.msgType == 4) {
                        item.showContent = L['[订单]'];
                    }
                })
            }
        }

        //切换会员事件
        const switchMember = (memberId, memberName, item) => {
            let oldMemberId = curMemberId.value;
            curMemberId.value = memberId;
            proxy.$socket.emit("admin_change_room", { memberId, oldMemberId, ...connectData });
            item.receiveMsgNumber = 0
            emit('switchMember', { memberId, memberName });
            // hasMore.value = true
            // chatList.list = []
            // getChatList()
        }

        //关闭与会员的聊天事件
        const closeChatMember = (memberId) => {

            proxy.$socket.emit("admin_remove_contact", { memberId, ...connectData });
            chatList.list = chatList.list.filter(item => item.memberId != memberId);
            if (curMemberId.value == memberId) {
                if (chatList.list.length > 0) {
                    //该会员与当前聊天的会员是同一个，需要先移除会员再切换
                    switchMember(chatList.list[0].memberId, chatList.list[0].memberName, chatList.list[0]);
                } else {
                    emit('curChatMemInfo', {})
                }
            }
            // console.log('memberId--', memberId)
            // ElMessageBox.confirm('确定结束与该会员的聊天吗？', {
            //     confirmButtonText: '确定',
            //     cancelButtonText: '取消',
            // }).then(() => {

            // })

        }


        // 向下滚动至底部加载数据
        const load = () => {
            if (hasMore.value) {
                isScroll.value = true
                getChatList()
            }
        }

        // 消息提示音
        const play = () => {
            let audioElement = document.createElement('audio');
            let voice = require('@/assets/voice/msg.mp3')
            audioElement.setAttribute('src', voice);
            audioElement.setAttribute('autoplay', 'autoplay');
        }

        onMounted(() => {

            clientHeight.value = document.body.clientHeight || document.documentElement.clientHeight
            getChatList();
            setTimeout(function () {
                getlineState()
                // 这里可以放置你需要执行的代码
            }, 100);

        })

        const emitParentData = (data) => {
            emit('switchMember', { memberId: data.memberId, memberName: data.memberName });
        }

        return {
            tabIndex,
            imgUrl,
            changeTab,
            chatList,
            storeInfo,
            formatMsgContent,
            curMemberId,
            switchMember,
            closeChatMember,
            load,
            loadState,
            emitParentData,
            connectData,
            clientHeight,
            L,
            adminInfo,
            adminName,
            adminLogo,
            play,
            getlineState,
            onlineStateValue,

        }
    }
}
</script>

<style lang="scss" scoped>
.el-popover {
    z-index: 10002 !important;
}

.sld_chat_left {
    width: 300px;
    /* border: 1px solid #9999; */
    flex-shrink: 0;

    .top {
        .info {
            display: flex;
            margin-top: 11px;

            &>img {
                width: 44px;
                height: 44px;
                margin-left: 20px;
            }

            .text {
                margin-left: 12px;

                p {
                    height: 23px;
                    display: flex;
                    align-items: center;

                    span {
                        display: inline-block;
                        height: 15px;
                        line-height: 15px;
                        margin-right: 10px;
                    }

                    &:nth-child(2) {

                        color: #51A4FD;
                    }

                    img {
                        width: 16px;
                        height: 16px;
                    }
                }
            }
        }
    }

    .list_wrap {
        margin-top: 10px;
        width: 100%;

        .tab {
            height: 46px;

            span {
                display: inline-block;
                text-align: center;
                height: 52px;
                padding: 17px 35px;
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #333333;
                border-bottom: 1px solid #f7f7f7;
                cursor: pointer;

                i {
                    font-style: normal;
                }
            }

            .tabChosen {
                border-bottom: 2px solid #0871FF;

                i {
                    width: 95px;
                    height: 17px;
                    font-size: 16px;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    color: #0B72F2;
                }
            }
        }

        .list_con {
            margin-top: 10px;

            .list_item {
                padding: 15px 17px;
                width: 97%;
                display: flex;
                align-items: center;
                border-bottom: 1px solid #f7f7f7;
                position: relative;

                &>img {
                    width: 40px;
                    height: 40px;
                }



                .list_text {
                    margin-left: 10px;

                    .list_item_title {
                        height: 15px;
                        line-height: 15px;
                        font-size: 14px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #333333;
                    }

                    .msg_new {
                        width: 175px;
                        margin-top: 9px;
                        font-size: 12px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #666666;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }

                .delete_icon {
                    position: absolute;
                    right: 17px;
                    display: flex;

                    i {
                        font-style: normal;
                        margin-right: 6px;
                        display: inline-block;
                        padding: 2px 5px;
                        background-color: #e2231a;
                        color: #fff;
                        font-size: 12px;
                        border-radius: 9px;
                        transform: scale(0.9)
                    }

                    .del_icon {
                        display: none;
                        cursor: pointer;
                    }

                }

                &:hover {
                    background-color: #F5F5F5;

                    .delete_icon {
                        .del_icon {

                            display: block;
                        }

                    }
                }
            }
        }
    }
}

.empty_data_left {
    height: 300px;
    padding-top: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
        width: 80px;
    }

    p {
        margin-top: 15px;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #D5D5D5;
    }
}
</style>